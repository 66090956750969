<template>
  <div
    class="text-success flex"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <span
      v-for="i in 5"
      :key="`start_${i}`"
      :class="[iconClass, !disabled ? 'is-clickable' : '']"
      @click="setVal(i)"
      @mouseover="setHover(i)"
    >
      <b-icon
        :icon="
          hovered
            ? i > hoveredValue && !disabled
              ? 'star-outline'
              : 'star'
            : i > innerValue
              ? 'star-outline'
              : 'star'
        "
        type="is-success"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    iconClass: {
      type: String,
      default: "md"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value,
      hoveredValue: null,
      hovered: false
    };
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  methods: {
    setVal(value) {
      if (!this.disabled) {
        this.innerValue = value;
        this.$emit("input", this.innerValue);
      }
    },
    setHover(value) {
      if (!this.disabled) {
        this.hoveredValue = value;
      }
    }
  }
};
</script>
